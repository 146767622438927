
.sav:nth-child(even) {
    background-color: #ececec47;
}

.sav-bottom-container {
    padding-top:15%;
    padding-bottom: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sav-title {
    text-align: center;
    color:white;
    background-color: #DDE1E4;
    font-size: 3.5em;
    padding-top: 15%;
    padding-bottom: 5%;
    margin: 0;
}

.sav-front {
    width: 60%;
    margin:auto;
}

.sav-front-container {
    padding-top: 15%;
    padding-bottom: 15%;
    margin-bottom: 15%;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}



.sav-front-desc {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.sav-body-container{
    width: 100%;
    padding-top:15%;
    padding-bottom: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sav-body-desc {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    text-align: center;
}

.sav-interior {
    width: 70%;
    padding-left:5%;
}

.sav-top {
    width: 70%;
    padding-right: 10%;
    padding-left : 10%;
}

.sav-interior-desc{
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    text-align: center;
}

.sav-interior-container {
    padding-top:15%;
    padding-bottom: 15%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sav-bottom-desc {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    text-align: center;
}

.sav-promo {
    background-color: #F7F7F7;
}

.design-title{
    text-align: center;
    font-size: 1.8em;
    margin-bottom: 5%;
}

@media only screen and (min-width: 441px){
    .sav-scale {
        max-width: 60%;
    }

}

@media only screen and (min-width: 768px) {
    .sav-front-container {
        flex-direction: row-reverse;
        margin-bottom: 5%;
        padding-bottom: 0;
    }

    .sav-front {
        width: 30%
    }

    .sav-body-container{
        flex-direction: row-reverse;
    }

    .sav-body-desc {
        align-self: baseline;
        margin-left: 10%;
    }

    .sav-interior-desc{
        align-self: flex-start;
        margin-right: 10%;
    }

    .sav-interior-container{
        flex-direction: row;
    }

    .sav-interior {
        width: 40%;
    }
    
    .sav-top {
        width: 40%;
    }

    .sav-body-desc {
        font-size: 1.4em;
    }

    .sav-interior-desc {
        font-size: 1.4em;
    }

    .sav-bottom-desc {
        font-size: 1.4em;
    }

    .sav-title {
        font-size: 5.4em;
    }

    .sav-front-desc {
        font-size: 1.4em;
    }
}

@media only screen and (min-width: 1150px){
    .sav-bottom-container img{
     max-width: 1200px;
    }
}


@media only screen and (min-width: 1400px){
    .sav-title{
        font-size: 7.4em;
    }

    .sav-body-desc {
        font-size: 1.9em;
    }

    .sav-interior-desc {
        font-size: 1.9em;
    }

    .sav-bottom-desc {
        font-size: 1.9em;
    }


    .sav-front-desc {
        font-size: 1.9em;
    }
}

@media only screen and (min-width: 1700px) {

    .save-page {
        width: 1700px;
    }
}