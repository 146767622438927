body {
    font-family: 'Titillium Web', sans-serif;
    background-color: #fafcfd;
}

main div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.link-decoration__off {
    text-decoration: none;
}

nav a, button {
    min-width: 48px;
    min-height: 48px;
}

@media only screen and (min-width: 1700px) {

    nav {
        width: 1770px;
    }

}
