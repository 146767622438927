.veratiles {
  display: flex;
  flex-direction: column;
}

.vera-sun {
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 500px;
}

.veratiles-bio {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.veratiles-bio-article {
  margin-left: auto;
  margin-right: auto;
}

.portrait {
  width: 100px;
  height: 100px;
  background-color: lightblue;
  align-self: center;
  border-radius: 50%;
}

.about-page {
  width: 100%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bio {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom:10%;
}

.bio-text {
  align-self: center;
  width:90%;
  font-size:1.2em;
}

.co-founder-bio {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media only screen and (min-width: 1700px) {
  .about-page {
    width: 1700px;
  }
}

@media only screen and (max-width: 440px){
    .bio {
       margin-bottom:20%; 
    }

}

@media only screen and (min-width: 441px) {
  
    .bio-text{
        max-width: 690px;
    }
}

@media only screen and (min-width:630px){
    .co-founder-bio{
        /* padding-top:10%; */
        flex-direction: row;
        align-items: baseline;
        padding-bottom:10%;
    }
}

@media only screen and (min-width:1200px){
    .bio-text{
        font-size:1.4em;
    }
}
