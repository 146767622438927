.footer {
    background-color: #c6c9cc;
    width: 100%;
    height:100px;
    display:flex;
    justify-content: center;
}

.footer-container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    color: white;
    flex-wrap: wrap;
    width: 300px;
}

.footer-email {
    color:white;
}

@media only screen and (min-width:515px){
    .footer-container{
        flex-direction: row;
    }
}