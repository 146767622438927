.nav-bar__item-link {
    color: black;
    text-decoration: none;
}

.nav-bar__item-link:hover {
    color: gray;
    border-bottom: black 2px solid;
}

.nav {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f7f7f7;
}

.nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav__list {
    width: 100%;
    padding: 0;
    margin: 0;
}

.nav__item {
    box-sizing: border-box;
    display: inline-block;
    width: 24%;
    text-align: center;
    line-height: 24px;
    padding: 24px;
    font-weight: bolder;
}

.nav-logo {
    width:48px;
    height:90px;
    padding: 0;
    margin-top:10px;
}